<template>
  <div class="speedbox">
    <div id="speedbox-score" :style="`transform: rotate(${speed}deg); border: 20px solid ${color};
                border-color: transparent transparent ${color} ${color};`"
         class="speedbox__score"
    ></div>
    <div class="speedbox__groove"></div>
    <div class="speedbox__odo">
      <div v-if="!hideValue3" class="speedbox__ping">{{ percent }}<span>%</span></div>
      <div v-if="max && !hideValue2" class="speedbox__up">{{ max }}<span>{{ unit }}</span></div>
      <div class="speedbox__down">{{ value }}<span>{{ unit }}</span></div>
    </div>
    <div class="speedbox__bg"></div>
    <div class="speedbox__base"></div>
    <h6 v-if="title" class="title">{{ title }}</h6>
  </div>
</template>

<script>
export default {
  name: 'speedometer',
  props: {
    min: {
      type: Number,
      required: false,
      default: 0,
    },
    max: {
      type: Number,
      required: false,
      default: 100,
    },
    value: {
      type: Number,
      required: true,
    },
    unit: {
      type: String,
      required: false,
      default: 'Gb',
    },
    title: {
      type: String,
      default: '',
    },
    invert: {
      type: Boolean,
      default: false,
    },
    hideValue2: {
      type: Boolean,
      default: false,
    },
    hideValue3: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    speed: function () {
      if (this.invert) {
        return Math.round((100 - (this.value / this.max * 100)) * 180 / 100) - 45;
      }
      return Math.round(this.value / this.max * 100 * 180 / 100) - 45;
    },
    percent: function () {
      if (this.invert) {
        return Math.ceil(100 - this.value / this.max * 100);
      }
      return Math.ceil(this.value / this.max * 100);
    },
    percentDegree: function () {
      return Math.round(this.percent * 180 / 100) - 45;
    },
    color: function () {
      if (this.percent >= 85) return '#e91e63';
      if (this.percent >= 70) return '#f57c00';
      if (this.percent >= 50) return '#fbc02d';
      return '#82B541';
    },
  }
}
</script>

<style lang="scss" scoped>
.speedbox {
  height: 200px;
  width: 200px;
  display: flex;
  display: -webkit-flex;
  flex-direction: column;
  -webkit-flex-direction: column;
  align-items: center;
  -webkit-align-items: center;
  position: relative;
  display: inline-flex;
  margin: 1rem;

  & .title {
    z-index: 100;
    position: absolute;
    bottom: 60px;
  }

  &__bg {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    background-color: #ffffff;
    z-index: 20;
  }

  &__groove {
    height: 100px;
    width: 200px;
    background: transparent;
    border-top-left-radius: 100px;
    border-top-right-radius: 100px;
    border: 20px solid #eee;
    border-bottom: 0;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__score {
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-0deg);
    height: 200px;
    width: 200px;
    background: transparent;
    border-radius: 50%;
    box-sizing: border-box;
    cursor: pointer;
    z-index: 1;
    transition: transform .3s ease;

  }

  &__base {
    width: 240px;
    height: 50px;
    background: white;
    position: relative;
    top: 100px;
    z-index: 20;

    &:before {
      content: '';
      width: 240px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      border-bottom: 1px solid #eee;
      box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.5);
    }
  }

  &__odo {
    text-align: center;
    position: absolute;
    color: #5c6f7b;
    bottom: 100px;
    left: 50%;
    transform: translateX(-50%);

    i {
      font-size: 13px;
      opacity: .6;
    }

    > div {
      margin-bottom: 0;
    }

    span {
      font-size: .7em;
    }
  }

  &__ping {
    font-size: 13px;
  }

  &__up {
    font-size: 13px;
    line-height: 1.5em;
  }

  &__down {
    font-size: 25px;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    line-height: 1.2em;
  }
}
</style>
